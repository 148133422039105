import MD5 from 'crypto-js/md5';

// import 'whatwg-fetch';
// import 'es6-promise';

export function sendToAuth(usrname: string, passwd: string, routerAuthAddr: string, remoteAuthAddr: string, useLocalGroups: boolean, appendLog: any, setIsLoading: any){
    const router_auth_addr = routerAuthAddr + ':10086/api/auth'
    fetch(router_auth_addr, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            'username': usrname,
            'password': MD5(passwd).toString().toLowerCase(),
            'remote_auth_addr': remoteAuthAddr+':10086/api/auth',
            'use_local_groups': useLocalGroups
        })
    }).then(res => res.json())
    .then(json => {
        if(json.auth === true) {
            appendLog('验证通过!请检查配置文件是否正常.软件启动需要一段时间，请不要关闭此页面，稍后会跳转到yacd...');
            // setTimeout("javascript:location.href='http://45.79.125.229:9090'", 5000);
            setTimeout("javascript:location.href='"+ routerAuthAddr + ":19090" +"'", 1000);
        }
        else{
            appendLog(json.msg)
        }
    }).catch(err => appendLog("失败!服务器发生错误，具体原因："+err)).finally(()=>setIsLoading(false));
}