import * as React from 'react';
import { sendToAuth } from './api';
import Grid, { GridSpacing } from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckBox from '@material-ui/core/Checkbox';
import { Menu } from '@material-ui/core';
import './login.css'

function App() {
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [useLocalGroupsChecked, setUseLocalGroupsChecked] = React.useState(true);
  const [routerAuthAddr, setRouterAuthAddr] = React.useState('http://192.168.5.1');
  const [remoteAuthAddr, setRemoteAuthAddr] = React.useState('http://45.79.125.229');
  const [isLoading, setIsLoading] = React.useState(false);
  // const [logInfo, setLogInfo] = React.useState('本页面是一个简单的验证界面，请联系管理员获取账号\n \
  //   注1: 本地网关网络控制api，负责接收账号密码，并提交给远程服务器验证; 即路由器Lan口地址\n \
  //   注2：远程验证服务api，负责调取用户数据，验证密码，若验证成功，则提供与配置文件相关的信息\n \
  //   ----------下面是本次登录的日志----------\n\n')
  const [logInfo, setLogInfo] = React.useState('')
  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };
  const handleRouterAddrChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRouterAuthAddr(event.target.value);
  };
  const handleRemoteAddrChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRemoteAuthAddr(event.target.value);
  };
  const appendLog = (info: string)=>{
    setLogInfo(prev => info + '\n');
  }
  const save = () => {
    setIsLoading(true);
    appendLog('登录中，这可能需要一点时间......')
    sendToAuth(username, password, routerAuthAddr, remoteAuthAddr, useLocalGroupsChecked, appendLog, setIsLoading);
  }
  const [isShowAdvancedOptions, setIsShowAdvancedOptions] = React.useState(false);
  const advancedOptions = (
    <div>
      <TextField id="ip-input" fullWidth={true} label="本地网关地址" value={routerAuthAddr} style={{display: "block", margin: "10px"}} onChange={handleRouterAddrChange} />
      <TextField id="r-input" fullWidth={true} label="远程验证服务器地址" value={remoteAuthAddr} style={{display: "block", margin: "10px"}} onChange={handleRemoteAddrChange} />
      <CheckBox id="c-input" checked={useLocalGroupsChecked} onChange={e=>setUseLocalGroupsChecked(e.target.checked)} /><div style={{display: "inline", fontSize: "90%"}}>加载本地分组信息</div>
    </div>
  )
  return (
    <>
    <div className="login_bg">
      <div className="login_content">
        <div className="login_left fl"><img src="lg_left.png" /></div>
        <div className="login_right fl">
          <div className="lgr_box">
            <h2>登录</h2>
            <div className="login_item">
              <span className="lg_icon fl"><img src="lg_user.png" /></span>
              <input id="username-input" placeholder="用户名" value={username} onChange={handleUsernameChange} className="login_input"/>
            </div>
            <div className="login_item">
              <span className="lg_icon fl"><img src="lg_pass.png" /></span>
              <input id="password-input" placeholder="密码" value={password} type="password" onChange={handlePasswordChange} className="login_input"/>
            </div>
            <button type="submit" className="login_btn" onClick={save} disabled={isLoading}>登录</button>
            <div className="login_item2" id="login_item2">
              <a href='javascript:void(0)' onClick={()=>{setIsShowAdvancedOptions(!isShowAdvancedOptions)}} >高级选项</a>
            </div>
            <Menu open={isShowAdvancedOptions} anchorEl={document.getElementById("login_item2")} onClose={()=>{setIsShowAdvancedOptions(false)}}  style={{ top: "100px"}}>
              {advancedOptions}
            </Menu>
            </div>
          </div>
      <div>
        <TextField
          multiline={true}
          maxRows={1}
          variant="standard"
          fullWidth
          label="登录日志"
          value={logInfo}
        />
      </div>
      </div>
    </div>
    </>
  );
}

export default App;
