import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Route, HashRouter } from 'react-router-dom';

import 'whatwg-fetch';
import 'es6-promise';

ReactDOM.render(
  <React.StrictMode>
    <HashRouter>
      <Route path="*" component={App} />
    </HashRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
